<template>
  <div>
    <el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px">
      <el-form-item :label="$t('agentForm.xm')" prop="name">
        <el-input v-model="form.name" :placeholder="   $t('agentForm.qsrxm')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('agentForm.yx')" prop="email">
        <el-input v-model="form.email" :placeholder="    $t('agentForm.qsuyx')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('agentForm.dh')" prop="phone">
        <el-input v-model="form.phone" :placeholder="   $t('agentForm.qsrdh')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('agentForm.nr')" prop="details">
        <el-input type="textarea" :rows="6" v-model="form.details" :placeholder="$t('agentForm.qsrnr')"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('ruleForm')">{{$t('agentForm.tj')}}</el-button>
        <el-button @click="resetForm('ruleForm')">{{$t('agentForm.cz')}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "BbjtwebsiteAgentForm",

  data() {
    let phone = (rule, value, callback) => {
      let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      if (!myreg.test(value)) {
        callback(this.$t('agentForm.qtxzqddhhm'))
      } else {
        callback()
      }
    };
    let email = (rule, value, callback) => {
      let myreg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (!myreg.test(value)) {
        callback(this.$t('agentForm.qtxzqdyx'))
      } else {
        callback()
      }
    };
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        details: ''
      },
      rules: {
          name: [
            { required: true, message: this.$t('agentForm.qcrxm'), trigger: 'blur' },
          ],
          phone: [
            {required: true, message: this.$t('agentForm.qcrsjh'), trigger: 'blur'},
            {validator: phone, trigger: 'change'},
          ],
          email: [
            {required: true, message: this.$t('agentForm.qsuyx'), trigger: 'blur'},
            {validator: email, trigger: 'change'},
          ],
          details: [
            { required: true, message: this.$t('agentForm.qcrnr'), trigger: 'blur' }
          ]
        }
    };
  },

  mounted() {
    
  },

  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addleaveMassage()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async addleaveMassage() {
      console.log(this.form);
      this.form.client_type = 3
      let res = await this.api.post(this.apiPath.leaveMassage,this.form);
      console.log(res);
      if(res.code == 200) {
        this.$message({
          message: res.message,
          type: 'success'
        });
      }else {
        this.$message({
          message: res.message,
          type: 'warning'
        });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
};
</script>

<style lang="less" scoped>
</style>